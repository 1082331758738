/* You can add global styles to this file, and also import other style files */
body {
  padding: 10px;
}

.flex {
  display: flex;
}

.grow {
  flex-grow: 1;
}

.text-center {
  text-align: center;
}

.mdc-tab__content, .mdc-tab__text-label {
  width: 100%;
}

.w-full {
  width: 100%;
}

.items-center {
  align-items: center;
}

.pointer-events-auto {
  pointer-events: auto;
}

.p-5 {
  padding: 1.25rem;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
